import { AError, ERROR_GROUPS } from "../../classes/AError.js";
import { EVENTS } from "../../services/AEventService.js";
import { AColor } from "../colors/AColor.js";
import { _getEle, asyncMapArray, convertObjectToArray } from "../../utils/tools.js";
import { AEngine } from "../AEngine.js";
import { ALL_GEO_MAPPING, ALL_GEO_TYPES, ALL_MAP_OPTIONS, MAP_OPTIONS, MAP_POSITION, UNLOAD_OPTIONS } from "./AMapStructs.js";
import { AGeoUtils } from "./AGeoUtils.js";
import { AMapOverlayService } from "./AMapOverlayService.js";
import { toggleMapFullScreen, toggleMapSearch } from "./mapToolsTmp.js";
import { polygons_tableformatter } from "../../utils/table_formatter.js";
export function DefaultBounds() {
    return new google.maps.LatLng(0, 0);
}
export function createMap(id, options) {
    const opts = $.extend(true, {}, options);
    const mapEle = _getEle(id);
    if (!mapEle) {
        throw new Error(`Couldn't find HTMLElement: #${id}`);
    }
    const map = new google.maps.Map(mapEle, $.extend(true, {
        _geoInstances: {},
        _geoObjectsVisible: {},
        scaleControl: false,
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP
        },
        mapTypeControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT
        },
    }, opts));
    return map;
}
// type ASessionMap = { [NodeName: string]: AMarker }
// type ADisplaySessionOnMapOptions = { lerpSpeed?: number } & { interpolate: boolean, sessions: ASessionMap }
// type ADisplaySessionOnMapOptionsPrivate = { lerpSpeed: number, map: any } & { interpolate: boolean, sessions: ASessionMap }
export class ACoreMapService {
    constructor() {
        this.scaleOpts = {};
        this.requested = {};
        // this.cache = {} as any // new Map<MAP_OPTIONS, {}>()
        this.geoObjects = {}; // new Map<MAP_OPTIONS, Map<number, GeoObject>>()
        this.lastArea = null;
    }
    autoInit() {
        this.mapOverlayService = AEngine.get(AMapOverlayService);
        Events.hardwire(EVENTS.PAGE_INITIALIZED, () => {
            this.refreshGeoLayerState();
        });
    }
    areGeoInstancesCached(map, input) {
        const k = ALL_GEO_TYPES.includes(input) ? input : this.mapOptionToGeoType(input);
        // return ((this.cache[k]?.length ?? 0) > 0) // .filter(v => v != null)
        return (map._geoInstances[k]?.length ?? 0) > 0;
    }
    getGeoInstancesOnMap(map, input) {
        const k = ALL_GEO_TYPES.includes(input) ? input : this.mapOptionToGeoType(input);
        return (map._geoInstances[k] || []).filter(v => v != null);
    }
    geoObjectMapper(mapOption) {
        return this.geoObjects[this.mapOptionToGeoType(mapOption)];
    }
    /**
     * Move polygons from one map to another
     * @param {any} map
     * @param {any} polygons
     */
    redirectPolygons(map, polygons) {
        polygons.map(p => {
            p.setMap(map);
            p.setOptions({ visible: true });
        });
    }
    fetchStreetData(coordinates) {
        return new Promise((resolve, reject) => {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'location': coordinates }, function (results, status) {
                if (status !== 'OK') {
                    return reject(status);
                }
                return resolve(results);
            });
        });
    }
    fetchStreetPosition(service, coordinates) {
        return new Promise((resolve, reject) => {
            try {
                service.getPanoramaByLocation(coordinates, 50, (res, status) => (status === 'OK') ? resolve(res.location.latLng) : resolve(false));
            }
            catch (err) {
                reject(err);
            }
        });
    }
    geoPointToCoords(data, srid = 4326) {
        //data = {"type": "Point", "coordinates": [10.750851301946682, 59.908290312191056]}
        // PageScript.map.setCenter(new google.maps.LatLng(59.9267065136985, 10.8019512068214))
        if (srid == 4326) {
            const [lng, lat] = data.coordinates;
            return { lat, lng };
        }
        else {
            const [lat, lng] = data.coordinates;
            return { lat, lng };
        }
    }
    geoJsonToPolygonCoords(data, srid = 4326) {
        const { coordinates } = data;
        let center = { lat: 0, lng: 0 };
        let areaPaths = [];
        for (let r = 0; r < coordinates.length; r++) {
            let ring = [];
            for (let p = 0; p < coordinates[r].length; p++) {
                let latlng;
                if (srid == 4326) {
                    latlng = { lat: coordinates[r][p][1], lng: coordinates[r][p][0] };
                }
                else {
                    latlng = { lat: coordinates[r][p][0], lng: coordinates[r][p][1] };
                }
                if (r == 0) {
                    center.lat += latlng.lat;
                    center.lng += latlng.lng;
                }
                ring.push(latlng);
            }
            if (r == 0) {
                center.lat /= coordinates[r].length;
                center.lng /= coordinates[r].length;
            }
            areaPaths.push(ring);
        }
        if (areaPaths.length == 1) {
            areaPaths = areaPaths[0];
        }
        return {
            coordinates: areaPaths,
            center: center
        };
    }
    get_legend_from_name(name, useOpacity) {
        switch (name) {
            case "green": return this.get_legend_green(useOpacity);
            case "yellow": return this.get_legend_yellow(useOpacity);
            case "orange": return this.get_legend_orange(useOpacity);
            case "red": return this.get_legend_red(useOpacity);
            case "blue": return this.get_legend_blue(useOpacity);
            case "grey": return this.get_legend_grey(useOpacity);
            case "brown": return this.get_legend_brown(useOpacity);
            case "white": return this.get_legend_white(useOpacity);
            case "black": return this.get_legend_black(useOpacity);
        }
        return null;
    }
    get_legend_green(useOpacity) {
        return {
            fill: useOpacity ? new AColor(0, 255, 0).rgba(0.7) : new AColor(0, 255, 0).hexi,
            stroke: new AColor(5, 163, 0).hexi
        };
    }
    get_legend_orange(useOpacity) {
        return {
            fill: useOpacity ? new AColor(255, 133, 0).rgba(0.7) : new AColor(255, 133, 0).hexi,
            stroke: new AColor(256, 128, 0).hexi
        };
    }
    get_legend_red(useOpacity) {
        return {
            fill: useOpacity ? new AColor(247, 0, 0).rgba(0.7) : new AColor(247, 0, 0).hexi,
            stroke: new AColor(185, 0, 0).hexi
        };
    }
    get_legend_grey(useOpacity) {
        // TODO: Implementation
        return {
            fill: useOpacity ? new AColor(128, 128, 128).rgba(0.7) : new AColor(128, 128, 128).hexi,
            stroke: new AColor(128, 128, 128).hexi
        };
    }
    get_legend_blue(useOpacity) {
        return {
            fill: useOpacity ? new AColor(0, 173, 255).rgba(0.7) : new AColor(0, 173, 255).hexi,
            stroke: new AColor(0, 114, 232).hexi
        };
    }
    get_legend_yellow(useOpacity) {
        return {
            fill: useOpacity ? new AColor(255, 255, 0).rgba(0.7) : new AColor(255, 255, 0).hexi,
            stroke: new AColor(255, 255, 0).hexi
        };
    }
    get_legend_brown(useOpacity) {
        return {
            fill: useOpacity ? new AColor(200, 100, 0).rgba(0.7) : new AColor(200, 100, 0).hexi,
            stroke: new AColor(200, 100, 0).hexi
        };
    }
    get_legend_white(useOpacity) {
        return {
            fill: useOpacity ? new AColor(255, 255, 255).rgba(0.7) : new AColor(255, 255, 255).hexi,
            stroke: new AColor(255, 255, 255).hexi
        };
    }
    get_legend_black(useOpacity) {
        return {
            fill: useOpacity ? new AColor(0, 0, 0).rgba(0.7) : new AColor(0, 0, 0).hexi,
            stroke: new AColor(0, 0, 0).hexi
        };
    }
    get_legend_brown_outline() {
        return {
            fill: new AColor(200, 100, 0).rgba(0.0),
            stroke: new AColor(200, 100, 0).hexi
        };
    }
    get_legend_spread(useOpacity) {
        return [
            {
                fill: useOpacity ? new AColor(27, 247, 20).rgba(0.7) : new AColor(27, 247, 20).hexi,
                stroke: new AColor(27, 247, 20).hexi
            },
            {
                fill: useOpacity ? new AColor(20, 247, 222).rgba(0.7) : new AColor(20, 247, 222).hexi,
                stroke: new AColor(20, 247, 222).hexi
            },
            {
                fill: useOpacity ? new AColor(31, 20, 247).rgba(0.7) : new AColor(31, 20, 247).hexi,
                stroke: new AColor(31, 20, 247).hexi
            },
            {
                fill: useOpacity ? new AColor(135, 20, 247).rgba(0.7) : new AColor(135, 20, 247).hexi,
                stroke: new AColor(135, 20, 247).hexi
            },
            {
                fill: useOpacity ? new AColor(240, 20, 247).rgba(0.7) : new AColor(240, 20, 247).hexi,
                stroke: new AColor(240, 20, 247).hexi
            },
            {
                fill: useOpacity ? new AColor(247, 20, 20).rgba(0.7) : new AColor(247, 20, 20).hexi,
                stroke: new AColor(247, 20, 20).hexi
            },
            {
                fill: useOpacity ? new AColor(247, 153, 20).rgba(0.7) : new AColor(247, 153, 20).hexi,
                stroke: new AColor(247, 153, 20).hexi
            },
            {
                fill: useOpacity ? new AColor(247, 243, 20).rgba(0.7) : new AColor(247, 243, 20).hexi,
                stroke: new AColor(247, 243, 20).hexi
            }
        ];
    }
    get legend_legacy() {
        const { legendItems } = {
            legendItems: {
                'Default': this.get_legend_green(true),
                'No Parking Right': this.get_legend_red(true),
                'Illegally Parked': this.get_legend_brown_outline(),
                'Unknown Parking Right': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ IsIllegallyParked, HasParkingRight }) => {
                const { FillColor, StrokeColor } = this.getColors({ IsIllegallyParked, HasParkingRight });
                return {
                    fill: FillColor,
                    stroke: StrokeColor
                };
            },
            legendItems
        };
    }
    get legend_digital() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'Digital': this.get_legend_green(false),
                'NotDigital': this.get_legend_red(false),
                'InProgress': this.get_legend_yellow(false),
                'NotProcessed': this.get_legend_grey(false)
            },
            legendItems: {
                'Digital': this.get_legend_green(true),
                'NotDigital': this.get_legend_red(true),
                'InProgress': this.get_legend_yellow(true),
                'NotProcessed': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ Digital }) => {
                if (Digital === null)
                    return null;
                switch (Digital) {
                    case 'InProgress': return calcTemplate.InProgress;
                    case 'Digital': return calcTemplate.Digital;
                    case 'NotDigital': return calcTemplate.NotDigital;
                    case 'NotProcessed': return calcTemplate.NotProcessed;
                }
            },
            legendItems
        };
    }
    get legend_illegallyparked() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'NotIllegallyParked': this.get_legend_green(false),
                'IllegallyParked': this.get_legend_red(false),
                'InProgress': this.get_legend_yellow(false),
                'NotProcessed': this.get_legend_grey(false)
            },
            legendItems: {
                'NotIllegallyParked': this.get_legend_green(true),
                'IllegallyParked': this.get_legend_red(true),
                'InProgress': this.get_legend_yellow(true),
                'NotProcessed': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ IllegallyParked }) => {
                if (IllegallyParked === null)
                    return null;
                switch (IllegallyParked) {
                    case 'NotIllegallyParked': return calcTemplate.NotIllegallyParked;
                    case 'InProgress': return calcTemplate.InProgress;
                    case 'NotProcessed': return calcTemplate.NotProcessed;
                }
                if (IllegallyParked?.startsWith('IllegallyParked')) {
                    return calcTemplate.IllegallyParked;
                }
            },
            legendItems
        };
    }
    get legend_parkingright() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'ParkingRight': this.get_legend_green(false),
                'NoParkingRight': this.get_legend_red(false),
                'InProgress': this.get_legend_yellow(false),
                'Other': this.get_legend_grey(false)
            },
            legendItems: {
                'ParkingRight': this.get_legend_green(true),
                'NoParkingRight': this.get_legend_red(true),
                'InProgress': this.get_legend_yellow(true),
                'Other': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ ParkingRight }) => {
                if (ParkingRight === null)
                    return null;
                switch (ParkingRight) {
                    case 'InProgress': return calcTemplate.InProgress;
                    case 'NoParkingRight': return calcTemplate.NoParkingRight;
                }
                if (ParkingRight?.startsWith('NoParkingRightNeeded')) {
                    return calcTemplate.Other;
                }
                if (ParkingRight?.startsWith('ParkingRight')) {
                    return calcTemplate.ParkingRight;
                }
                if (ParkingRight?.startsWith('Indecisive')) {
                    return calcTemplate.NoParkingRight;
                }
                if (ParkingRight?.startsWith('NotProcessed')) {
                    return calcTemplate.Other;
                }
            },
            legendItems
        };
    }
    get legend_verification() {
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'NoVerificationNeeded': this.get_legend_green(false),
                'FinedIllegallyParked': this.get_legend_red(false),
                'FinedNoParkingRight': this.get_legend_blue(false),
                'InProgress': this.get_legend_yellow(false),
                'NotProcessed': this.get_legend_grey(false)
            },
            legendItems: {
                // TODO: Remove NoVerificationNeeded (Bespreek met Jaap ivm met limit 2000 scans, moet ik de query aanpassen ofz?)
                'NoVerificationNeeded': this.get_legend_green(true),
                'FinedIllegallyParked': this.get_legend_red(true),
                'FinedNoParkingRight': this.get_legend_blue(true),
                'InProgress': this.get_legend_yellow(true),
                'NotProcessed': this.get_legend_grey(true)
            }
        };
        return {
            calcColor: ({ Verification }) => {
                if (Verification === null)
                    return null;
                if (['NoVerificationNeeded', 'NotFined'].includes(Verification)) {
                    return calcTemplate.NoVerificationNeeded;
                }
                if (['Fined_IllegallyParked', 'Fined_TimeLimitedParkingExperired'].includes(Verification)) {
                    return calcTemplate.FinedIllegallyParked;
                }
                if (['Fined_NoParkingRight', 'Fined_Unknown'].includes(Verification)) {
                    return calcTemplate.FinedNoParkingRight;
                }
                if (Verification?.startsWith('InProgress')) {
                    return calcTemplate.InProgress;
                }
                if (Verification?.startsWith('NotProcessed')) {
                    return calcTemplate.NotProcessed;
                }
            },
            legendItems
        };
    }
    get legend_detection_state() {
        const colorSpread = this.get_legend_spread(false);
        const colorSpreadOpacity = this.get_legend_spread(true);
        const { calcTemplate, legendItems } = {
            calcTemplate: {
                'GeoQueue': colorSpread.pop(),
                'PrdbQueue': colorSpread.pop(),
                'PdaVerificationQueue': colorSpread.pop(),
                'CentralVerificationQueue': colorSpread.pop(),
                'AssignedToPda': colorSpread.pop(),
                'AssignedToCentralVerification': colorSpread.pop(),
                'Unknown': colorSpread.pop(),
                'Done': colorSpread.pop()
            },
            legendItems: {
                'GeoQueue': colorSpreadOpacity.pop(),
                'PrdbQueue': colorSpreadOpacity.pop(),
                'PdaVerificationQueue': colorSpreadOpacity.pop(),
                'CentralVerificationQueue': colorSpreadOpacity.pop(),
                'AssignedToPda': colorSpreadOpacity.pop(),
                'AssignedToCentralVerification': colorSpreadOpacity.pop(),
                'Unknown': colorSpreadOpacity.pop(),
                'Done': colorSpreadOpacity.pop()
            }
        };
        return {
            calcColor: ({ DetectionState }) => {
                if (DetectionState === null)
                    return null;
                switch (DetectionState) {
                    case 'InProgress_GeoQueue': return calcTemplate.GeoQueue;
                    case 'InProgress_PrdbQueue': return calcTemplate.PrdbQueue;
                    case 'InProgress_PdaVerificationQueue': return calcTemplate.PdaVerificationQueue;
                    case 'InProgress_CentralVerificationQueue': return calcTemplate.CentralVerificationQueue;
                    case 'InProgress_AssignedToPda': return calcTemplate.AssignedToPda;
                    case 'InProgress_AssignedToCentralVerification': return calcTemplate.AssignedToCentralVerification;
                    case 'InProgress_Unknown': return calcTemplate.Unknown;
                    case 'Done': return calcTemplate.Done;
                }
            },
            legendItems
        };
    }
    set calcLegendColor(val) {
        throw new Error(`Not Implemented Yet!`);
    }
    get calcLegendColor() {
        const fallbackClr = { fill: '#000', stroke: '#000' };
        const calcColor = PageScript.__map_legend_calcColor;
        if (calcColor == undefined) {
            AError.handleSilent(`Page calcLegendColor has not been initialized!`);
            return (_ => (fallbackClr));
        }
        const resultFunc = (args) => {
            const clr = calcColor(args);
            if (clr === null) {
                AError.handle({
                    err: new Error(`AMapHelperService.calcLegendColor unification is null data=${JSON.stringify(args)}`),
                    useAdminAlerts: false,
                    useCentralServerLogging: false,
                    useModal: false,
                });
            }
            else if (clr === undefined) {
                AError.handleSilent(`AMapHelperService.calcLegendColor unexpected unification (POSSIBLY MISSING UNIFICATIONS) data=${JSON.stringify(args)}`, ERROR_GROUPS.CalcLegendColorError);
            }
            return clr || fallbackClr;
        };
        return resultFunc;
    }
    get tabDefinitions() {
        console.error(`// TODO: Remove hardcoded aspect`);
        const tabOptions = [
            'ParkingRight',
            'IllegallyParked',
            'Verification',
            'DetectionState',
            'Digital',
            'Default'
        ];
        const tabConfigurations = {
            'Digital': this.legend_digital,
            'IllegallyParked': this.legend_illegallyparked,
            'ParkingRight': this.legend_parkingright,
            'Verification': this.legend_verification,
            'DetectionState': this.legend_detection_state,
            'Default': this.legend_legacy
        };
        return { tabOptions, tabConfigurations };
    }
    async generateDetectionsLegendHtml({ legendItems }) {
        // const { tabOptions } = this.mapOverlayService.tabDefinitions
        // TODO: Fix hardcoded poop
        const { tabOptions } = this.tabDefinitions;
        const [legendLabelText, translations, tabOptionsT,] = await Promise.all([
            Translate.get('Legend'),
            Translate.get(Object.keys(/** @type {object} */ (legendItems))),
            Translate.get(tabOptions)
        ]);
        const LEGEND_SELECTION = this.legendSelection || tabOptions[0];
        const HTML_SELECT = tabOptions.map(tabOption => (`
            <option${LEGEND_SELECTION == tabOption ? ' selected="selected"' : ''} value="${tabOption}">${tabOptionsT[tabOption]}</option>
         `).trim()).join('\r\n');
        const HTML_LEGEND_ITEMS = Object.keys(legendItems).map(key => {
            const { stroke, fill } = legendItems[key];
            return (`
        <div>
          <div class="detectionPreview" style="background-color: ${fill}; outline-color: ${stroke}"></div>
          <span>${translations[key]}</span>
        </div>
      `).trim();
        }).join('\r\n');
        const style = (`zoom: ${globalThis.AConfig?.get(`drawing & colors.legendScale`, 1.2) ?? 1.2};`);
        return (`
      <div class="legend legend-opaque legend-detections" style="${style}">
        <div class="legend-label label-height-lg">${legendLabelText}</div>
        <select class="changeLegend">${HTML_SELECT}</select>
        ${HTML_LEGEND_ITEMS}
      </div>
    `);
    }
    async setDetectionsLegendDefault({ mapElement }) {
        const { tabConfigurations } = this.tabDefinitions;
        const view = tabConfigurations[this.legendSelection] || this.legend_parkingright;
        await Loading.waitForPromises(this.setDetectionsLegend(mapElement, view)).catch(AError.handle);
    }
    async setDetectionsLegend(mapElement, options) {
        const { calcColor } = options;
        const { tabConfigurations } = this.tabDefinitions;
        const $detectionsLegend = $(await this.generateDetectionsLegendHtml(options));
        const $select = $detectionsLegend.find('.changeLegend');
        $select.on('change', _ => {
            this.legendSelection = $select.val();
            Loading.waitForPromises(this.setDetectionsLegend(mapElement, tabConfigurations[$select.val()])).catch(AError.handle);
        });
        this.calcLegendColor = calcColor;
        this.mapOverlayService.add(mapElement, $detectionsLegend, MAP_POSITION.BOTTOM_LEFT, { uid: 'LEGEND_CONTROLS', order: 0 });
        await this.recolorAllMarkers({ calcColor });
    }
    async recolorAllMarkers(options) {
        const fallbackClr = { fill: '#000', stroke: '#000' };
        const calcColor = options.calcColor || this.calcLegendColor;
        const markers = this.fetchMarkers();
        await asyncMapArray(markers, 10, (marker) => {
            const optClr = calcColor(marker._final, { opacity: true });
            if (optClr === null) {
                AError.handle({
                    err: new Error(`AMapHelperService.recolorAllMarkers unification is null data=${JSON.stringify(marker._final)}`),
                    useAdminAlerts: false,
                    useCentralServerLogging: false,
                    useModal: false,
                });
            }
            else if (optClr === undefined) {
                AError.handleSilent(`AMapHelperService.recolorAllMarkers unexpected unification (POSSIBLY MISSING UNIFICATIONS) data=${JSON.stringify(marker._final)}`, ERROR_GROUPS.CalcMarkerColorError);
            }
            const clr = optClr || fallbackClr;
            const { fill, stroke } = (typeof clr === 'string') ? { fill: clr, stroke: clr } : clr;
            marker.setOptions({
                strokeColor: stroke,
                fillColor: fill
            });
        });
    }
    async createCountLabel() {
        const mapCountLabel = document.createElement('label');
        mapCountLabel.classList.add('map-control-count');
        const template = await Translate.get('Detections Displayed: 808');
        mapCountLabel.setAttribute('template', template);
        mapCountLabel.innerText = template.replace('808', '-');
        PageScript.map?.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(mapCountLabel);
    }
    updateCountLabel(count) {
        const $count = $('.map-control-count');
        if ($count.length) {
            const template = $count.attr('template') || '';
            const text = template.replace('808', count);
            $count.text(text);
        }
    }
    async createDownloadButton(options) {
        const { mapElement, order } = options;
        const $button = await menuService.addMapButton({
            mapElement,
            tag: 'a',
            title: 'Export kml',
            titleTag: 'span',
            icon: 'fa-solid fa-file-arrow-down',
            order,
            position: MAP_POSITION.TOP_RIGHT,
        });
        $button.attr('id', 'DownloadButton');
        $button.attr('disabled', 'disabled');
        return $button;
    }
    geoTypeToMapOption(geoType) {
        for (let opt in MAP_OPTIONS) {
            let isKeyNumber = Number(opt) >= 0;
            if (!isKeyNumber && opt === geoType) {
                return MAP_OPTIONS[opt];
            }
        }
        return MAP_OPTIONS.None;
    }
    mapOptionToGeoType(type) {
        for (let opt of ALL_MAP_OPTIONS) {
            if (opt & type) {
                return MAP_OPTIONS[type];
            }
        }
        throw new Error(`UnknownGeoType: ${type}`);
    }
    /**
     * @deprecated
     */
    showGeoObjectOnMap(map, area, geoType, geoObject, result, bounds, clickEvent) {
        const mapOption = this.geoTypeToMapOption(geoType);
        const colorConfig = this.getGeoObjectColor(mapOption, geoObject);
        const zIndex = this.getGeoObjectZIndex(mapOption);
        switch (geoObject.Geo.type) {
            case "Point": {
                const [lng, lat] = geoObject.Geo.coordinates;
                let mapsGeo = new google.maps.Marker({
                    position: new google.maps.LatLng(lat, lng)
                });
                Object.assign(mapsGeo, {
                    data: {
                        id: geoObject.Index,
                        area: area,
                        scale: mapOption,
                        geoType: geoType
                    }
                });
                result.push(mapsGeo);
                mapsGeo.setMap(map);
                google.maps.event.addListener(mapsGeo, "click", clickEvent || this.onGeoClick);
                return;
            }
            case "LineString": {
                let parkingSpaceBounds = geoObject.Geo.coordinates;
                let path = [];
                let center = { lat: 0, lng: 0 };
                for (let p = 0; p < parkingSpaceBounds.length; p++) {
                    let latlng = {
                        lat: parkingSpaceBounds[p][1],
                        lng: parkingSpaceBounds[p][0]
                    };
                    if (bounds)
                        bounds.extend(latlng);
                    center.lat += latlng.lat;
                    center.lng += latlng.lng;
                    path.push(latlng);
                }
                center.lat /= parkingSpaceBounds.length;
                center.lng /= parkingSpaceBounds.length;
                geoObject.Center = center;
                // Construct the polygon, including both paths.
                let mapsGeo = new google.maps.Polyline({
                    path: path,
                    strokeColor: colorConfig.strokeColor,
                    strokeOpacity: colorConfig.strokeOpacity,
                    strokeWeight: 3,
                    // fillColor: colorConfig.fillColor,
                    // fillOpacity: colorConfig.fillOpacity,
                    zIndex: zIndex,
                    // position: center
                });
                Object.assign(mapsGeo, {
                    data: {
                        id: geoObject.Index,
                        area: area,
                        scale: mapOption,
                        geoType: geoType
                    }
                });
                geoObject.RefList.push(mapsGeo);
                result.push(mapsGeo);
                mapsGeo.setMap(map);
                google.maps.event.addListener(mapsGeo, "click", clickEvent || this.onGeoClick);
                return;
            }
            case "Polygon": {
                let parkingSpaceBounds = geoObject.Geo.coordinates;
                let parkingSpacePaths = [];
                let center = { lat: 0, lng: 0 };
                for (let r = 0; r < parkingSpaceBounds.length; r++) {
                    center = { lat: 0, lng: 0 };
                    let ring = [];
                    for (let p = 0; p < parkingSpaceBounds[r].length; p++) {
                        let latlng = {
                            lat: parkingSpaceBounds[r][p][1],
                            lng: parkingSpaceBounds[r][p][0]
                        };
                        if (bounds)
                            bounds.extend(latlng);
                        center.lat += latlng.lat;
                        center.lng += latlng.lng;
                        ring.push(latlng);
                    }
                    center.lat /= parkingSpaceBounds[r].length;
                    center.lng /= parkingSpaceBounds[r].length;
                    parkingSpacePaths.push(ring);
                }
                if (parkingSpacePaths.length == 1) {
                    parkingSpacePaths = parkingSpacePaths[0];
                }
                geoObject.Center = center;
                // Construct the polygon, including both paths.
                let mapsGeo = new google.maps.Polygon({
                    paths: parkingSpacePaths,
                    strokeColor: colorConfig.strokeColor,
                    strokeOpacity: colorConfig.strokeOpacity,
                    strokeWeight: .5,
                    fillColor: colorConfig.fillColor,
                    fillOpacity: colorConfig.fillOpacity,
                    zIndex: zIndex,
                    // position: center
                });
                Object.assign(mapsGeo, {
                    data: {
                        id: geoObject.Index,
                        area: area,
                        scale: mapOption,
                        geoType: geoType
                    }
                });
                // Apply ref for fast lookup
                geoObject.RefList.push(mapsGeo);
                result.push(mapsGeo);
                mapsGeo.setMap(map);
                google.maps.event.addListener(mapsGeo, "click", clickEvent || this.onGeoClick);
                return;
            }
            case "MultiPolygon": {
                for (let g = 0; g < geoObject.Geo.coordinates.length; g++) {
                    let parkingSpaceBounds = geoObject.Geo.coordinates[g];
                    let parkingSpacePaths = [];
                    let center = { lat: 0, lng: 0 };
                    for (let r = 0; r < parkingSpaceBounds.length; r++) {
                        center = { lat: 0, lng: 0 };
                        let ring = [];
                        for (let p = 0; p < parkingSpaceBounds[r].length; p++) {
                            let latlng = {
                                lat: parkingSpaceBounds[r][p][1],
                                lng: parkingSpaceBounds[r][p][0]
                            };
                            if (bounds)
                                bounds.extend(latlng);
                            center.lat += latlng.lat;
                            center.lng += latlng.lng;
                            ring.push(latlng);
                        }
                        center.lat /= parkingSpaceBounds[r].length;
                        center.lng /= parkingSpaceBounds[r].length;
                        parkingSpacePaths.push(ring);
                    }
                    if (parkingSpacePaths.length == 1) {
                        parkingSpacePaths = parkingSpacePaths[0];
                    }
                    geoObject.Center = center;
                    // Construct the polygon, including both paths.
                    let mapsGeo = new google.maps.Polygon({
                        paths: parkingSpacePaths,
                        strokeColor: colorConfig.strokeColor,
                        strokeOpacity: colorConfig.strokeOpacity,
                        strokeWeight: .5,
                        fillColor: colorConfig.fillColor,
                        fillOpacity: colorConfig.fillOpacity,
                        zIndex: zIndex,
                        // position: center
                    });
                    Object.assign(mapsGeo, {
                        data: {
                            id: geoObject.Index,
                            area: area,
                            scale: mapOption,
                            geoType: geoType
                        }
                    });
                    // Apply ref for fast lookup
                    geoObject.RefList.push(mapsGeo);
                    result.push(mapsGeo);
                    mapsGeo.setMap(map);
                    google.maps.event.addListener(mapsGeo, "click", clickEvent || this.onGeoClick);
                }
                return;
            }
            case "Collection": /// ACCCServer incorrectly calls this Collection (http://gitserver/aci-software/ACCCServer/issues/28)
            case "GeometryCollection":
                let geometries = geoObject.Geo.geometries || geoObject.Geo.items; /// ACCCServer incorrectly calls this items  (http://gitserver/aci-software/ACCCServer/issues/28)
                for (let i = 0; i < geometries.length; i++) {
                    let newGeoObject = { Index: geoObject.Index, Geo: geometries[i], Name: geoObject.Name, Attributes: geoObject.Attributes, Created: geoObject.Created, Modified: geoObject.Modified, RefList: geoObject.RefList, Center: geoObject.Center };
                    this.showGeoObjectOnMap(map, area, geoType, newGeoObject, result, bounds, clickEvent);
                }
                return;
            default:
                throw new Error(`Unknown geo json type: ${geoObject.Geo.type}`);
        }
    }
    /**
     * @deprecated
     */
    async showGeoObjectsOnMap(geoType, map, bounds, clickEvent) {
        if (this.cache[geoType]) {
            if (clickEvent) {
                for (const polygon of this.cache[geoType] ?? []) {
                    google.maps.event.addListener(polygon, "click", clickEvent);
                }
            }
            this.redirectPolygons(map, this.cache[geoType] ?? []);
            return this.cache[geoType];
        }
        let result = [];
        await asyncMapArray(Object.keys(this.geoObjects[geoType] ?? []), 100, (id) => {
            return this.showGeoObjectOnMap(map, null, geoType, this.geoObjects[geoType][id], result, bounds, clickEvent);
        });
        this.cache[geoType] = result;
        return result;
    }
    /**
     * Creates a toggle button on the map to hide a category
     * @param bitmask only apply on specific type of polygons on the map
     * @param options options
     */
    async createMapToggleSettings(bitmask, { map, order, fitPolygons = true, showOneScale, click, enableExperimentalLoading }) {
        map._geoObjectsVisible = {};
        const t = await Translate.get(ALL_GEO_TYPES);
        let categories = ALL_GEO_MAPPING.map(({ geoType, mapOption }) => {
            map._geoObjectsVisible[geoType] = false;
            return (mapOption & bitmask) ? { geoType, mapOption, text: t[geoType] ?? '?', isCheckbox: true, enableExperimentalLoading } : null;
        }).filter(v => v != null);
        if (showOneScale === true) {
            const bounds = map.getBounds() || new google.maps.LatLngBounds();
            await Loading.waitForPromises(categories.map(async ({ geoType }) => {
                await this.showGeoObjectsOnMap.call(this, geoType, map, bounds, click);
            }));
            if (fitPolygons === true) {
                map.fit(bounds);
            }
        }
        const toggleTo = (geoType, $c) => this.toggleTo(map, geoType, click).finally(() => $c.map($c => $c.trigger('refreshstate')));
        const toggle = (geoType, $c) => this.toggle(map, geoType, click, !map._geoObjectsVisible[geoType])
            .finally(() => $c.map($c => $c.trigger('refreshstate')));
        const toggleAction = showOneScale ? toggleTo : toggle;
        const selectDefault = (showOneScale === true) ? ($c) => toggleAction('ParkingSpace', $c) : undefined;
        const elements = categories.map((c) => this.genMapPopoverItem(map, c));
        const $checkboxes = elements.map(e => e.$checkbox);
        elements.map(({ geoType, $checkbox }) => {
            $checkbox.on('change', _ => Loading.waitForPromises(toggleAction(geoType, $checkboxes)).catch(AError.handle));
        });
        if (selectDefault != null) {
            Loading.waitForPromises(selectDefault($checkboxes)).catch(AError.handle);
        }
        if (bitmask !== 0) {
            menuService.addMapDropdown(elements.map(e => e.$a), {
                mapElement: map.getDiv(),
                uid: 'scale-toggle',
                icon: 'fa-solid fa-draw-polygon',
                order,
                position: MAP_POSITION.TOP_LEFT
            });
        }
    }
    /**
     * @deprecated
     * Creates a toggle button on the map to hide a category
     * @param bitmask only apply on specific type of polygons on the map
     * @param options options
     */
    async createMapToggleSettings2(bitmask, { map, order, enableExperimentalLoading }) {
        // let categories: AMapToggleCategory[] = []
        // PageScript.geoObjectsVisible = {}
        // // type AFuncShowOnMap = (map: any, bounds: any, filterArea: any, clickEvent: any) => Promise<any>
        // // let methods: AFuncShowOnMap[] = []
        // const mapOptionToFunc = (_enum: MAP_OPTIONS) => {
        //   return ({ parseToMap }: { parseToMap: boolean }) => {
        //     return AEngine.get(AGeoService).load(_enum, { parseToMap, addClickListener: true }) as Promise<(APolygon | APolyline)[]>
        //   }
        // }
        // ALL_MAP_OPTIONS.map((mapOption: MAP_OPTIONS) => {
        //   if (bitmask & mapOption) {
        //     const optName = MAP_OPTIONS[mapOption]
        //     categories.push({
        //       enableExperimentalLoading: enableExperimentalLoading,
        //       id: AEngine.get(AIdAllocatorService).getNextId({ prefix: 'tgl-' }),
        //       isCheckbox: true,
        //       enum: mapOption,
        //       mapOption: mapOption,
        //       displayText: optName,
        //       boolKey: `v${optName}`,
        //       collectionKey: `${optName}OnMap`,
        //       updateState: ({ visible }) => {
        //         console.log('updateState', { visible })
        //       },
        //       loadPolygons: mapOptionToFunc(mapOption)
        //     })
        //   }
        // })
        // await Loading.waitForPromises(Translate.get(categories.map(({ displayText }) => displayText))).then(t => {
        //   categories.map(c => c.displayText = t[c.displayText])
        // })
        // const $checkboxArr: JQuery[] = []
        // const inputs: JQuery[] = await Promise.all(
        //   categories.map(async (category, i) => {
        //     const { displayText, mapOption } = category
        //     PageScript.geoObjectsVisible[mapOption] = false
        //     const $a = this.genMapPopoverItem({ displayText, id: `category-${category.id}-${i}` })
        //     const $checkbox = $a.find('[type="checkbox"]')
        //     $checkboxArr.push($checkbox)
        //     const toggleAction: () => Promise<any> = async () => {
        //       await Loading.waitForPromises(
        //         this.toggleAny(category, !PageScript.geoObjectsVisible[mapOption])
        //       ).catch(AError.handle)
        //       // await this.toggle(this.getMapOption(collectionKey as any), !PageScript[boolKey])
        //       $checkboxArr.map($c => $c.trigger('refreshstate'))
        //     }
        //     $checkbox.on('refreshstate', (e) => {
        //       if (PageScript.geoObjectsVisible) {
        //         $checkbox.prop('checked', PageScript.geoObjectsVisible[mapOption])
        //       }
        //     })
        //     $checkbox.on('change', _ => Loading.waitForPromises(toggleAction()).catch(AError.handle))
        //     $a.on('click', (e) => {
        //       e.preventDefault()
        //       e.stopPropagation()
        //       $checkbox.trigger('change')
        //     })
        //     return $a
        //   })
        // )
        // if (bitmask !== 0) {
        //   menuService.addMapDropdown(inputs, {
        //     mapElement: map.getDiv(),
        //     uid: 'scale-toggle',
        //     icon: 'fa-solid fa-draw-polygon',
        //     order,
        //     position: MAP_POSITION.TOP_LEFT
        //   })
        // }
    }
    genMapPopoverItem(map, { geoType, text }) {
        const $a = $(/*html*/ `
      <a geoType="${geoType}" isLoaded="${geoService.isGeoDataCached(geoType) ? '1' : '0'}">
        <div class="noselect ns-children">
          <label class="form-checkbox">
            <input type="checkbox" class="hidden noselect"> <i class="form-icon"></i>
            ${text}
          </label>
        </div>
      </a>
    `);
        const $checkbox = $a.find(`[type="checkbox"]`);
        $checkbox.on('refreshstate', (e) => {
            $a.attr('isLoaded', geoService.isGeoDataCached(geoType) ? '1' : '0');
            $checkbox.prop('checked', (map._geoObjectsVisible ?? {})[geoType]);
        });
        $a.on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            $checkbox.trigger('change');
            $checkbox.trigger('refreshstate');
        });
        Events.on('GeoResponseStored', () => {
            this.refreshGeoLayerState(geoType);
        });
        return { $a, $checkbox, geoType };
    }
    refreshGeoLayerState(geoType) {
        const attrSelector = geoType !== undefined ? `geotype="${geoType}"` : 'geotype';
        const $aArr = $(`.aci-map [uid="scale-toggle"] a[${attrSelector}]`).toArray().map(a => $(a));
        $aArr.map($a => { $a.find('[type="checkbox"]').trigger('refreshstate'); });
    }
    editStyle({ map, find, defaultValue, behaviour }) {
        const styles = map.get('styles') || [];
        let index = -1;
        styles.map((style, i) => {
            if (find(style)) {
                index = i;
            }
        });
        if (index === -1) {
            index = styles.push(defaultValue()) - 1;
        }
        styles[index] = behaviour(styles[index]);
        map.set('styles', styles);
    }
    toggleMapLabels(map) {
        if (!PageScript.__mapOptions) {
            PageScript.__mapOptions = { visibility: true };
        }
        const featureType = 'all', elementType = 'labels';
        this.editStyle({
            map,
            find: (style) => (style.featureType === featureType && style.elementType === elementType),
            defaultValue: () => ({
                featureType,
                elementType,
                stylers: [{
                        visibility: PageScript.__mapOptions.visibility ? "on" : "off"
                    }]
            }),
            behaviour: (style) => {
                PageScript.__mapOptions.visibility = !PageScript.__mapOptions.visibility;
                style.stylers[0].visibility = PageScript.__mapOptions.visibility ? "on" : "off";
                return style;
            }
        });
    }
    setMapType(map, mapType) {
        map.setMapTypeId(mapType);
    }
    genDropdownLink({ displayText, isCheckbox = false }) {
        return (isCheckbox) ? $(`
      <a>
        <div class="noselect ns-children">
          <label class="form-checkbox">
            <input type="checkbox" class="hidden noselect">
            <i class="form-icon"></i> ${displayText}
          </label>
        </div>
      </a>
    `) : $(`<a><div class="noselect ns-children">${displayText}</div></a>`);
    }
    async createMapDropdown({ map, mapElement, order }) {
        let categories = [
            { displayText: 'Toggle Labels', click: () => this.toggleMapLabels(map) },
            { displayText: 'Focus On Detections', click: () => map.focusOnMarkers() },
            { displayText: 'Focus On Geo Layers', click: () => map.focusOnScales() },
            { displayText: 'Reset View', click: () => map.resetBounds() },
        ];
        await Loading.waitForPromises(Translate.get(categories.map(({ displayText }) => displayText))).then(t => {
            categories.map(c => c.displayText = t[c.displayText]);
        });
        const inputs = categories.map(category => {
            const $link = this.genDropdownLink(category);
            const $c = $link.find('[type=checkbox]');
            let toggle = false;
            let toggleAction = async () => {
                category.click.call(this);
                $c.prop('checked', toggle);
                toggle = !toggle;
            };
            $link.on('click', () => { toggleAction().catch(AError.handle); });
            $c.on('change', () => { toggleAction().catch(AError.handle); });
            return $link;
        });
        menuService.addMapDropdown(inputs, {
            mapElement,
            icon: 'fa-solid fa-bars',
            order,
            position: MAP_POSITION.TOP_LEFT
        });
    }
    /**
     * @deprecated
     * Updates visibility of polygons, so if the area isn't selected, the polygon won't show up
     */
    updateStaticPolygons(map) {
        // @ts-ignore
        const cache = map?._geoInstances ?? this.cache ?? {};
        const geoObjectsVisible = map?._geoObjectsVisible ?? PageScript.geoObjectsVisible;
        for (let geoType in cache) {
            AEngine.warn(`// TODO: Update to use map instance data geoObjectsVisible`);
            if (geoObjectsVisible && geoObjectsVisible[geoType]) {
                cache[geoType].map(scale => scale.setOptions({ visible: true }));
            }
        }
        return Promise.resolve();
    }
    getGeoObjectColor(mapOption, geoObject) {
        // const defaultColorConf = { strokeColor: '#880000', strokeOpacity: 1.0, fillColor: '#FF0000', fillOpacity: 0.5 }
        // const geoType = this.mapOptionToGeoType(mapOption)
        // // TODO: Find out what to use for coloring
        // if (geoObject.Attributes["KLEUR"]) {
        //   const hexColor = geoObject.Attributes["KLEUR"]
        //   colorConfig.fillColor = hexColor
        //   colorConfig.strokeColor = new AColor(hexColor).hsv.lerpTo(new AColor('#000000').hsv, 0.5).hexi
        // }
        // if (geoObject.Attributes["BO.Color"]) {
        //   const bo_color = this.getLegendColor(geoObject.Attributes["BO.Color"], true)
        //   if (bo_color) {
        //     colorConfig.fillColor = bo_color.fill
        //     colorConfig.strokeColor = bo_color.stroke
        //   }
        // }
        return { strokeColor: '#880000', strokeOpacity: 1.0, fillColor: '#FF0000', fillOpacity: 0.5 };
    }
    getGeoObjectZIndex(mapOption) {
        switch (mapOption) {
            case MAP_OPTIONS.Region: return .1;
            case MAP_OPTIONS.Area: return .2;
            case MAP_OPTIONS.RouteArea: return .3;
            case MAP_OPTIONS.Segment: return .4;
            case MAP_OPTIONS.Zone: return .5;
            case MAP_OPTIONS.TemporaryZone: return .55;
            case MAP_OPTIONS.ParkingSpace: return .6;
            case MAP_OPTIONS.GeneratedParkingSpace: return .7;
            case MAP_OPTIONS.SplitParkingSpace: return .8;
            case MAP_OPTIONS.DirectedWaySegment: return .9;
            case MAP_OPTIONS.WaySegment: return 1.0;
            case MAP_OPTIONS.RouteOption: return 1.1;
            case MAP_OPTIONS.RouteIntersection: return 1.2;
            case MAP_OPTIONS.Address: return 1.3;
            case MAP_OPTIONS.ParkingMachine: return 1.4;
        }
    }
    /**
     * Toggles all polygon scales off except for the one given as argument
     * @param geoType
     */
    async toggleTo(map, geoType, click) {
        await Promise.all([
            this.toggle(map, geoType, click, true),
            ...Object.keys(map._geoObjectsVisible).map((key) => {
                const geoType = key;
                return (map._geoObjectsVisible[geoType] === true) ? this.toggle(map, geoType, click, false) : Promise.resolve();
            }),
        ]);
    }
    async toggle(map, input, clickEvent, visible) {
        try {
            // const isGeoType = ALL_GEO_TYPES.includes(input)
            const geoType = (typeof input === 'number') ? this.mapOptionToGeoType(input) : input;
            const mapOption = this.geoTypeToMapOption(geoType);
            // if (this.cache[geoType]) {
            // if (clickEvent) {
            //   for (const polygon of this.cache[geoType] ?? []) {
            //     google.maps.event.addListener(polygon, "click", clickEvent)
            //   }
            // }
            // this.redirectPolygons(map, this.cache[geoType] ?? [])
            // return this.cache[geoType]
            // }
            // TODO: Move geoObjectsVisible to map instances
            if (!map._geoObjectsVisible) {
                throw new Error(`AMap.geoObjectsVisible is not defined!`);
            }
            map._geoObjectsVisible[geoType] = (visible !== undefined) ? visible : !map._geoObjectsVisible[geoType];
            visible = map._geoObjectsVisible[geoType];
            AEngine.warn(`Setting %p${geoType}%c Visible:`, visible);
            if (this.areGeoInstancesCached(map, mapOption)) {
                await asyncMapArray(this.getGeoInstancesOnMap(map, mapOption) ?? [], 100, (geoInstance) => { geoInstance.setOptions({ map, visible: visible }); });
                return;
            }
            if (visible === true) {
                // if (!geoService.isGeoDataCached(geoType)) {
                //   // TODO: ....
                //   await geoService.load(map, mapOption, {parseToMap: true, addClickListener: clickEvent})
                //   return
                // }
                await geoService.load(map, mapOption, { parseToMap: true, addClickListener: clickEvent });
                return;
            }
            await asyncMapArray(this.getGeoInstancesOnMap(map, mapOption) ?? [], 100, (geoInstance) => { geoInstance.setOptions({ map, visible: visible }); });
        }
        catch (err) {
            AError.handle(err);
        }
    }
    async toggleAny(opt, visibleOpt) {
        // const { collectionKey, boolKey, loadPolygons } = opt
        // if (!this.hasOwnProperty(collectionKey)) {
        //   this[collectionKey] = await loadPolygons({ parseToMap: true, ignoreOutsideBounds: true })
        // }
        // const visible = (visibleOpt !== undefined) ? visibleOpt : PageScript[boolKey]
        // const collection = this[collectionKey]
        // PageScript[boolKey] = visible
        // if (collection instanceof Array) {
        //   await geoService.setVisibility(collection, { parseToMap: visible, ignoreOutsideBounds: true })
        //   await asyncMapArray(collection, 100, (polygon) => {
        //     polygon.setOptions({ visible })
        //   })
        // } else {
        //   await asyncMapArray(Object.keys(collection), 100, (key) => {
        //     collection[key].setOptions({ visible })
        //   })
        // }
        // if (opt.enableExperimentalLoading === true) {
        //   this.initExperimentalLoading(opt)
        // }
    }
    initExperimentalLoading(opt) {
        AEngine.error(`// TODO: Implementation`);
        // if (this.scaleOpts.hasOwnProperty(collectionKey)) {
        //   if (PageScript[boolKey] === false) {
        //     this.scaleOpts[collectionKey].listener.remove()
        //     delete this.scaleOpts[collectionKey]
        //   }
        //   return
        // }
        // const listener = google.maps.event.addListener(PageScript.map, "idle", () => {
        //   this.experimentalRefresh(this.scaleOpts[collectionKey].array)
        // })
        // this.scaleOpts[collectionKey] = {
        //   array: this[collectionKey],
        //   listener: listener
        // }
    }
    async experimentalRefresh(geoInstances) {
        await geoService.setVisibility(geoInstances, { parseToMap: true, ignoreOutsideBounds: true });
    }
    prepareMapItems(bitmask, options) {
        const opt = Object.assign({
            showSearch: false,
            showOneScale: false,
            createToggleItems: true,
            updateStaticPolygons: false,
            allowExport: false,
            showLegend: false,
            fitPolygons: false,
            click: coreMapService.onGeoClick,
            enableExperimentalLoading: false,
        }, options || {});
        return Loading.waitForPromises(this._prepareMapItems(bitmask, opt));
    }
    async _prepareMapItems(bitmask, options) {
        const { createToggleItems, updateStaticPolygons, showLegend, allowExport, showOneScale, click, fitPolygons, enableExperimentalLoading, createLabel, showSearch, skipFit } = options;
        const map = options.map || PageScript.map;
        const mapElement = map.getDiv();
        const output = await Loading.waitForPromises([
            Promise.resolve().then(() => this.mapOverlayService.addGradientOverlay(mapElement)),
            // Map Hamburger menu
            this.createMapDropdown({ order: 1, map, mapElement }),
            // Map Scale toggle menu
            (createToggleItems === true) ? this.createMapToggleSettings(bitmask, { order: 2, showOneScale, click, fitPolygons, enableExperimentalLoading, map }) : Promise.resolve(),
            // Map Fullscreen button
            menuService.addMapButton({
                mapElement,
                order: 3,
                icon: 'fa-solid fa-expand',
                position: MAP_POSITION.TOP_LEFT
            }).then($btn => {
                $btn.on('click', _ => toggleMapFullScreen({ mapElement }));
                const $fa = $btn.find('i');
                Events.on(EVENTS.TOGGLE_FULLSCREEN, (v) => {
                    $fa.toggleClass('fa-compress', v);
                    $fa.toggleClass('fa-expand', !v);
                });
            }),
            Promise.resolve().then(() => console.error(`// TODO: Implement ShowSearch`)),
            (showSearch) ? menuService.addMapButton({
                mapElement,
                order: 4,
                icon: 'fa-solid fa-magnifying-glass',
                position: MAP_POSITION.TOP_LEFT,
            }).then($btn => {
                const $fa = $btn.find('i');
                $btn.on('click', () => {
                    const v = toggleMapSearch({
                        ele: '#map',
                        map: map
                    });
                    $fa.toggleClass('fa-magnifying-glass', !v);
                    $fa.toggleClass('fa-xmark', v);
                });
            }) : Promise.resolve(),
            menuService.addMapButtonRadio({
                mapElement,
                order: 99,
                titles: ['Map', 'Sattelite'],
                icons: ['fa-regular fa-leaf-maple', 'fa-solid fa-satellite-dish'],
                position: MAP_POSITION.TOP_RIGHT
            }).then(([$map, $sattelite]) => {
                $map.on('click', _ => this.setMapType(map, google.maps.MapTypeId.ROADMAP));
                $sattelite.on('click', _ => this.setMapType(map, google.maps.MapTypeId.HYBRID));
            }),
            // Update polygon visibility
            (updateStaticPolygons === true) ? this.updateStaticPolygons(map) : Promise.resolve(),
            // Show legend if enabled
            (showLegend === true) ? this.setDetectionsLegendDefault({ mapElement }) : Promise.resolve(),
            // Show export if enabled
            (allowExport) ? this.createDownloadButton({ order: 10, mapElement }) : Promise.resolve(),
            // Create detection count at the bottom of the map
            (createLabel === true) ? this.createCountLabel() : Promise.resolve(),
            // Change map bounds to fit the contents
            (skipFit !== true) ? Promise.resolve().then(_ => map?.fit()) : Promise.resolve(),
            // Create safe-zone for streetview btn to appear
            (map?.streetViewControlOptions?.position === 7) ? Promise.resolve()
                .then(_ => AEngine.get(AMapOverlayService).setOffset(mapElement, MAP_POSITION.TOP_RIGHT, map?.streetViewControl ? '0 45px 0 0' : '0')) : Promise.resolve()
        ]);
        // Initialize resizing events
        const $map = $(mapElement);
        if (!$map.is('.map-sm,.map-xs')) {
            const onSizeChange = () => {
                const $dragParent = $map.closest('.aci-draggable-view').css('min-width', '245px');
                const collisionData = this.mapOverlayService.isCollisionBetween(mapElement, MAP_POSITION.TOP_LEFT, MAP_POSITION.TOP_RIGHT, { checkX: true });
                if (collisionData.isCollision && !$map.is('.map-xs')) {
                    const $overlay = this.mapOverlayService.getOrCreateOverlay(mapElement, MAP_POSITION.TOP_LEFT);
                    $overlay.css('width', $overlay.width());
                    $map.addClass('map-xs');
                }
                if (!collisionData.isCollision && $map.is('.map-xs')) {
                    const $overlay = this.mapOverlayService.getOrCreateOverlay(mapElement, MAP_POSITION.TOP_LEFT);
                    $overlay.css('width', '');
                    $map.removeClass('map-xs');
                }
                const isSmall = $map.width() < 600;
                $map.toggleClass('map-sm', isSmall && !collisionData.isCollision);
            };
            google.maps.event.addListener(map, 'resize', onSizeChange);
            Events.on(EVENTS.CONTENT_DRAG, onSizeChange);
        }
        this.refreshGeoLayerState();
        return output;
    }
    /**
     * Changes the color of the marker
     * @param {any} marker
     * @param {Object} options
     */
    changeColorMarker(marker, options) {
        marker.setOptions(options);
    }
    /**
     * @deprecated
     * Reverts polygon colors & opacity to the initial colors
     * @param bitmask
     */
    revertColors(bitmask = MAP_OPTIONS.All) {
        ALL_MAP_OPTIONS.filter(opt => (opt & bitmask)).map((opt) => {
            const geoType = this.mapOptionToGeoType(opt);
            if (this.cache[geoType]?.length) {
                this.cache[geoType].map((marker) => {
                    const { strokeColor, strokeOpacity, fillColor, fillOpacity } = marker.data;
                    marker.setOptions({
                        strokeColor, strokeOpacity, fillColor, fillOpacity
                    });
                });
            }
        });
    }
    /**
     * Finds the markers that are saved to the PageScript object
     */
    fetchMarkers() {
        return PageScript.Markers || PageScript.markers || [];
    }
    /**
     * Deletes polygons (zones, areas, parking spaces or markers) from the map
     * @param {any} arrayOrObject
     */
    destroy(arrayOrObject) {
        if (arrayOrObject === undefined)
            return;
        const eventsToClear = ['click', 'rightclick'];
        if (arrayOrObject instanceof Array) {
            let array = arrayOrObject;
            if (array.length === 0) {
                return array;
            }
            for (let index in array) {
                for (const key of eventsToClear) {
                    google.maps.event.clearListeners(array[index], key);
                }
                array[index].setMap(null);
                delete array[index];
            }
            if (array.length) {
                array.length = 0;
                // @ts-ignore
                array = null;
            }
        }
        else {
            this.destroy(convertObjectToArray(arrayOrObject));
        }
    }
    /**
     * Unloads polygons (zones, areas, parking spaces or markers) from the map
     * @param {any} arrayOrObject polygons on the map
     * @param {Number} options type of unload
     */
    unload(arrayOrObject, options = UNLOAD_OPTIONS.AllListeners) {
        const eventsToClear = [
            'bounds_changed',
            'center_changed',
            'click',
            'dblclick',
            'drag',
            'dragend',
            'dragstart',
            'heading_changed',
            'idle',
            'maptypeid_changed',
            'mousemove',
            'mouseout',
            'mouseover',
            'projection_changed',
            'resize',
            'rightclick',
            'tilesloaded',
            'tilt_changed',
            'zoom_changed',
        ];
        if (arrayOrObject instanceof Array) {
            const array = arrayOrObject;
            if (array.length === 0) {
                return array;
            }
            switch (options) {
                case UNLOAD_OPTIONS.Default:
                    for (let index in array) {
                        // if (array.hasOwnProperty(index)) {
                        for (const key of eventsToClear) {
                            google.maps.event.clearListeners(array[index], key);
                        }
                        array[index].setMap(null);
                        // }
                    }
                    break;
                case UNLOAD_OPTIONS.AllListeners:
                    for (let index in array) {
                        // if (array.hasOwnProperty(index)) {
                        for (const key of eventsToClear) {
                            google.maps.event.clearListeners(array[index], key);
                        }
                        array[index].setMap(null);
                        // }
                    }
                    break;
                case UNLOAD_OPTIONS.None:
                    for (let index in array) {
                        // if (array.hasOwnProperty(index)) {
                        array[index].setMap(null);
                        // }
                    }
                    break;
                default:
                    throw new Error(`MapHelperService.unload(... , ${options}) contains unknown options.`);
            }
        }
        else {
            this.unload(convertObjectToArray(arrayOrObject));
        }
    }
    getColors(detectionData) {
        let FillColor = '';
        let StrokeColor = '';
        const set = (f, o) => {
            if (f !== null && FillColor === '')
                FillColor = f;
            if (o !== null && StrokeColor === '')
                StrokeColor = o;
        };
        let { IsIllegallyParked, HasParkingRight } = detectionData;
        if (HasParkingRight === null) {
            const { fill, stroke } = this.get_legend_grey(false); // AConfig.get('drawing & colors.detections.unknown')
            set(fill, stroke);
        }
        if (HasParkingRight === 0) {
            const { fill, stroke } = this.get_legend_red(false); // AConfig.get('drawing & colors.detections.noParkingRight')
            set(fill, stroke);
        }
        if (IsIllegallyParked) {
            const { stroke } = this.get_legend_brown_outline(); // AConfig.get('drawing & colors.detections.illegallyParked')
            set(null, stroke);
        }
        if (FillColor === null) {
            const { fill, stroke } = this.get_legend_green(false); // AConfig.get('drawing & colors.detections.default')
            set(fill, stroke);
        }
        return { FillColor, StrokeColor };
    }
    /**
     * Gets the bounds of the map
     */
    getMapBounds() {
        const output = {};
        const { map } = PageScript;
        if (!map) {
            return null;
        }
        const bounds = map.getBounds();
        const json = bounds.toJSON !== undefined ? bounds.toJSON() : bounds.JSON();
        Object.keys(json).map((key) => {
            if (key.length) {
                const newKey = key[0].toUpperCase() + key.substr(1);
                Object.assign(output, { [newKey]: json[key] });
            }
        });
        return output;
    }
    /**
     * Gets points of a Polygon or Marker
     * @param {any} marker Polygon or Marker
     */
    getPoints(marker) {
        if (marker == null) {
            throw new Error(`AMapHelperService.getPoints unexpected marker is not defined!`);
        }
        const path = marker.getPath();
        const length = path.getLength();
        const lnglat = [];
        for (let i = 0; i < length; i++) {
            const { lat, lng } = path.getAt(i).toJSON();
            lnglat.push([lng, lat]);
        }
        return lnglat;
    }
    onGeoClick(event) {
        AEngine.log('ACoreMapService.onGeoClick(event?: %cMouseEvent%n) %g event=', event);
        const geoInstance = this;
        const pos = AGeoUtils.calcCenter(geoInstance);
        purgatoryService.buildAndShowInfoWindowLegacy({
            data: geoInstance.data,
            marker: geoInstance,
            tableFormatter: polygons_tableformatter(),
            greyOutFields: true,
            sorting: [],
        });
        Events.tryInvoke(EVENTS.GEO_OBJECT_CLICKED, { geoInstance, pos });
    }
    addClickListeners(geoInstances, clickEvent) {
        const clickEventHandler = clickEvent ?? this.onGeoClick;
        geoInstances.map((geoInstance) => {
            google.maps.event.addListener(geoInstance, "click", clickEventHandler);
        });
    }
    addClickListener(geoInstance, clickEvent) {
        const clickEventHandler = clickEvent ?? this.onGeoClick;
        google.maps.event.addListener(geoInstance, "click", clickEventHandler);
    }
}
